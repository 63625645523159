import React from 'react';
import LockRounded from '@material-ui/icons/LockRounded';
import CancelRounded from '@material-ui/icons/CancelRounded';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import {Box, Grid, Step, StepLabel, Stepper, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const ICONS = {
    null: <LockRounded htmlColor={'#b9b9b9'}/>,
    0: <CancelRounded color={'error'}/>,
    1: <CheckCircleRounded htmlColor={'#17ab00'}/>,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
}));

function PracticePipeline({practice}) {
    const classes = useStyles();

    const pipelinePoints = practice.pipelinePoints;

    if (!pipelinePoints || (pipelinePoints?.length === 0)) return null;

    //sort by position asc
    pipelinePoints.sort((a, b) => a.position - b.position);

    const handleLabelClick = (url) => window.open(url, '_blank');

    return (
        <Grid container className={classes.root}>
            <Box>
                <Stepper activeStep={pipelinePoints.length - 1} alternativeLabel>
                    {pipelinePoints.map((point) => {
                        const Component = <StepLabel StepIconProps={{icon: ICONS[point.status]}}>
                            {point.href_url ? <span style={{color: 'blue', cursor: 'pointer'}}
                                                    onClick={() => handleLabelClick(point.href_url)}>
                                        {point.label}
                                    </span> : point.label}
                            {point.required ?
                                <Typography style={{display: 'inline-block'}}
                                            color={'error'}>*</Typography> : ''}
                        </StepLabel>;

                        const withTooltip = point.tooltip_label;

                        return (
                            <Step key={point.label}>
                                {
                                    withTooltip ?
                                        <Tooltip title={point.tooltip_label} placement={'top'}>
                                            {Component}
                                        </Tooltip> :
                                        Component
                                }
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            <Grid item>
                <Typography variant={'caption'} color={'error'}>* Step obbligatori</Typography>
            </Grid>
        </Grid>
    );
}

export default PracticePipeline;
